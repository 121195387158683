import React from "react";
import "../../../home/home.css";
import JobDetailsContent from "./JobDetailsContent";

const JobDetails = () => {

    return (
        <>
            
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0.9s"
                >
                    <p className="text-white">
                        <span className="title">Career</span>
                        <span className="subTitle">
                        We would love to hear from you if you are a problem solver
                        </span>
                    </p>
                </div>
            </section>

            <main className="main">
                <section
                    id="pricing"
                    className="pricing"
                    style={{ marginTop: "-50px" }}
                >
                    <div className="container pricing" data-aos="fade-up">
                        
                        <div id="hr" className="row" data-aos="fade-left">
                           
                            <JobDetailsContent/>
                                
                        </div>
                        
                    </div>
                </section>
            </main>
            
        </>
    );
};

export default JobDetails;
