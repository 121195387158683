import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';
import './aboutUsPage.css';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const TeamPage = () => {

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title'>Our Team</span>
                        <span className='sub-title'>It is a long established fact that a reader will be.</span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#hr' scroll={el => scrollWithOffset(el)} >HR</Link>
                                        <Link smooth to='#developer' scroll={el => scrollWithOffset(el)} >Developer</Link>
                                        <Link smooth to='#designer' scroll={el => scrollWithOffset(el)} >Designer</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section id="pricing" className="pricing" style={{ marginTop:'-50px' }}>
                    <div className="container pricing" data-aos="fade-up">                        
                        {/* Web Design Start */}
                        <div id='hr' className="row" data-aos="fade-left">
                            <div className='teamTitle'>
                                <h1>HR Team</h1>
                            </div>
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Annette Black</li>
                                        <li>Medical Assistant</li>
                                    </ul>
                                </div>
                            </div>                     
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Marketing Coordinator</li>
                                    </ul>
                                </div>
                            </div>                             
                        </div>
                        {/* Web Design End */}
                                                
                        {/* Web Design Start */}
                        <div id='developer' className="row" data-aos="fade-left"> 
                            <div className='teamTitle'>
                                <h1>Developer Team </h1>  
                            </div>                     
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Nec feugiat nisl</li>
                                    </ul>
                                </div>
                            </div>                      
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Nec feugiat nisl</li>
                                    </ul>
                                </div>
                            </div>                     
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Nec feugiat nisl</li>
                                    </ul>
                                </div>
                            </div>                               
                        </div>
                        {/* Web Design End */}  

                        {/* Web Design Start */}
                        <div id='designer' className="row" data-aos="fade-left">
                            <div className='teamTitle'>
                                <h1>Designer Team </h1>
                            </div>
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Nec feugiat nisl</li>
                                    </ul>
                                </div>
                            </div>                     
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">                                
                                <div className="cardBox">
                                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                    <ul>
                                        <li className='name'>Dianne Russell</li>
                                        <li>Nec feugiat nisl</li>
                                    </ul>
                                </div>
                            </div>                             
                        </div>
                        {/* Web Design End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default TeamPage;