import React from "react";
import "../../../home/home.css";

const PartnersData = () => {
    
    return (
        <>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/a2Hosting.webp" alt="" />
                    </a>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/namecheap-logo.webp" alt="" />
                    </a>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/microsoft-partner.webp" alt="" />
                    </a>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/microsoft.webp" alt="" />
                    </a>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/redHat.webp" alt="" />
                    </a>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#f" rel="noreferrer">
                        <img className="img-fluids" src="assets/img/partners/solutionSquad.webp" alt="" />
                    </a>
                </div>
            </div>
        </>
    );
};

export default PartnersData;
