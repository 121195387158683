const HelpCenterItem=[
    {
        id:1,
        cat:'knowledged',
        title:'Knowledged Base',
        desc:'You can find KMS / blog from here...',
        image: 'assets/img/aboutUs/about1.jpg',
        url:'/knowledged-base' 
    },
    {
        id:2,
        cat:'contactus',
        title:'Contact Us',
        desc:'Contact us for free consultancy...',
        image: 'assets/img/menu/web_solution_firm-contactus.webp',
        url:'/contact-us'
    },
];

export default HelpCenterItem;