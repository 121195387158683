import { BrowserRouter, Route, Routes } from "react-router-dom";

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import "./App.css";
import Header1 from "./home/Header1";
import Home from "./home/Home";


/* Solution Menu Start */
import EcommerceSolution from "./pages/solutionPages/EcommerceSolution";
import EdTechSolution from "./pages/solutionPages/EdTechSolution";
import SmallMediumBusiness from "./pages/solutionPages/SmallMediumBusiness";
import CorporateSolution from "./pages/solutionPages/CorporateSolution";
import ConsultencySolution from "./pages/solutionPages/ConsultencySolution";
import DigitalMarketingSEO from "./pages/solutionPages/DigitalMarketingSEO";
/* Solution Menu End */

/* Services Menu Start*/
import WebApplication from "./pages/servicesPages/WebApplication";
import SoftWareDevelopment from "./pages/servicesPages/SoftwareDevelopment";
import MobileApplication from "./pages/servicesPages/MobileApplication";
import CreativePage from "./pages/servicesPages/CreativePage";
import DigitalMarketing from "./pages/servicesPages/DigitalMarketing";
import DomainHosting from "./pages/servicesPages/DomainHosting";

import Portfolio from "./pages/portfolio/portfolioDetails";
import ContactUsPage from "./pages/helpCenter/ContactUsPage.";
/* Services Menu End*/

/* Company Menu  Start*/
import AboutUsPage from "./pages/aboutUs/AboutUsPage";
import TeamPage from "./pages/aboutUs/TeamPage";
import Clients from "./pages/company/ourClients/Clients";
import Partners from "./pages/company/ourPartners/Partners";
import TestimonialPage from "./pages/company/testimonial/TestimonialPage";

// Career Sub Menu
import CareerPage from "./pages/company/career/CareerPage";
import JobDetails from "./pages/company/career/JobDetails";
import ApplyJob from "./pages/company/career/ApplyJob";
/* Company Menu  End*/

import ScrollToTop from "./home/ScrollToTop";

import Footer from "./home/Footer";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop/>
        <Header1 />

        <Routes>
          <Route path="/*" exact="/*" element={<Home />} />

          {/* Solution Menu */}
          <Route path="ecommerce-solution" exact="ecommerce-solution" element={<EcommerceSolution />} />
          <Route path="edtech" exact="edtech" element={<EdTechSolution />} />
          <Route path="small-to-medium-business" exact="small-to-medium-business" element={<SmallMediumBusiness />} />
          <Route path="corporate" exact="corporate" element={<CorporateSolution />} />
          <Route path="consultency" exact="consultency" element={<ConsultencySolution />} />
          <Route path="others" exact="others" element={<DigitalMarketingSEO />} />
          {/* Services Menu */}
          <Route path="web-application-details" exact="web-application-details" element={<WebApplication />} />
          <Route path="software-development-details" exact="software-development-details" element={<SoftWareDevelopment />} />
          <Route path="mobile-application-details" exact="mobile-application-details" element={<MobileApplication />} />
          <Route path="creative-details" exact="creative-details" element={<CreativePage />} />
          <Route path="digital-marketing-and-seo-details" exact="digital-marketing-and-seo-details" element={<DigitalMarketing />} />
          <Route path="domain-and-hosting-details" exact="domain-and-hosting-details" element={<DomainHosting />} />

          <Route path="portfolio" exact="portfolio" element={<Portfolio />} />
          <Route path="contact-us" exact="contact-us" element={<ContactUsPage />} />

          {/* Company Menu */}
          <Route path="our-clients" exact="our-clients" element={<Clients />} />
          <Route path="our-technology-partners" exact="our-technology-partners" element={<Partners />} />
          <Route path="about-us-pages" exact="about-us-pages" element={<AboutUsPage />} />
          <Route path="our-team-details" exact="our-team-details" element={<TeamPage />} />
          <Route path="testimonials" exact="testimonials" element={<TestimonialPage />} />

          {/* Career */}
          <Route path="career-page" element={<CareerPage/>} />

          {/* <Route path="career-page/:jobId" element = {<JobDetails/>} /> */}

          <Route path="/:slug" element = {<JobDetails/>} />

          <Route path="apply-job-:jobId" element={<ApplyJob />} />

        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
