import React, {useState} from 'react';
import './home.css';
import {Link, NavLink} from 'react-router-dom';

// import headerLogo from '../assets/images/logo/headerLogo.png';
// import headerLogo from '../assets/images/logo/wsfHeaderLogo.gif';
import footerLogo from '../assets/images/logo/footerLogo.png';
import Solution from './solution/Solution';
import Services from './services/Services';
import AboutUs from './company/Company';
import HelpCenter from './helpCenter/HelpCenter';

const Header1 = () => {

    // Start Header bg color change when scroll

    const [header, setHeader] = useState(false);

    const changeBackground = () =>{
        if(window.scrollY >= 90){
        setHeader(true)
        }else {
        setHeader(false)
        }
    }

    window.addEventListener('scroll', changeBackground);
    
    // End Header bg color change

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <>
            {/* <!-- ======= Header ======= --> */}  
            {/* <header className="header fixed-top"> */}
            <header className={header ? 'header fixed-top' : 'header'}>
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo d-flex align-items-center">
                        <img src={footerLogo} alt="" />
                    </Link>

                    <nav className={isNavExpanded ? 'navbar-mobile' : 'navbar'} id="navbar">
                    {/* <nav className="navbar-mobile navbar" id="navbar"> */}
                        <ul>
                            <li className="dropdown solutionDropdown"><NavLink to="/solution"><span>Solution</span> <i className="bi bi-chevron-down"></i></NavLink>
                                <ul>
                                    <div className="row">
                                        <Solution />
                                    </div>
                                </ul>
                            </li>
                            <li className="dropdown servicesDropdown"><NavLink to="/web-application-details"><span>Services</span> <i className="bi bi-chevron-down"></i></NavLink>
                                <ul>
                                    <div className="row">
                                        <Services />
                                    </div>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/portfolio" >Portfolio</NavLink>
                            </li> 
                            <li className="dropdown aboutUsDropdown"><NavLink to="/about-us"><span>Company</span> <i className="bi bi-chevron-down"></i></NavLink>
                                <ul>
                                    <div className="row">
                                        <AboutUs />
                                    </div>
                                </ul> 
                            </li>
                            <li className="dropdown m-r-10"><NavLink to="/help-center"><span>Help Center</span> <i className="bi bi-chevron-down"></i></NavLink>
                                <ul className='helpDropdown'>
                                    <div className="row">
                                        <HelpCenter />
                                    </div>
                                </ul> 
                            </li>
                        </ul>

                        <i
                            onClick={() => {
                                setIsNavExpanded(!isNavExpanded)
                            }} 
                            className={isNavExpanded ? 'bi bi-x-circle mobile-nav-toggle' : 'bi bi-list mobile-nav-toggle'}
                        ></i>

                    </nav>
                </div>     
            </header>
        </>
    );
};

export default Header1;