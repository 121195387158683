import React from 'react';
import LandingPage from '../home/LandingPage';
import Video from './Video';

const Home = () => {
      
    return (
        <>
          <div className='video'>
            <Video />
          </div>            

          <main className="main">
            <LandingPage />
          </main>
          
        </>
    );
};

export default Home;