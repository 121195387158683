import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

import "../../../home/home.css";

const JobDetailsContent = () => {

  const { slug } = useParams();
  // const jobId = props.match.params.productId;

  const [state, setState] = useState({});

  useEffect(() => {
      myFunction();
      return () => {
        setState({}); // This worked for me
      };
  },[]);
  
  const myFunction = () => {
    axios.get(`https://nz.websolutionfirm.com/api/job-details/${slug}`).then((res) => {
      if (res.status === 200) {
        setState(res.data.details);
      }
    });
  }


  return (
    <>

      <div className="col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
        <div className="cardBox" id="cardBox" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-md-12" style={{ padding: "15px 50px" }}>
              <h2 style={{ padding: "20px" }}>
                <b>{state.titleOrDesignationn}</b>
              </h2>
              <div className="jobSummary" style={{overflowX:"auto"}}>
                <table>
                  <tbody>
                    <tr>
                      <td>Published on: {state.publishedDate}</td>
                      <td>Vacancy: {state.vacancy}</td>
                      <td>Employment Status: {state.employmentStatus}</td>
                      <td>Salary: {state.salary}</td>
                    </tr>
                    <tr>
                      <td>Application Deadline: {state.applicationDeadline}</td>
                      <td>Experience: {state.yearOfExperience}</td>
                      <td>Job Location: {state.jobLocation}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-12 col-12">
              <ul>
                <li style={{ paddingBottom: "0px" }}>
                  <span style={{ paddingRight: "10px" }}></span>
                  <div
                      dangerouslySetInnerHTML={{ __html: state.jobContext }}
                      style={{width:"100%"}}
                  ></div>
                </li>
              </ul>
            </div>

            <div
              className="applyNow col-md-12"
              style={{ padding: "15px 50px 50px 50px" }}
            >
              <div className="text-center text-rg-start">
                  <Link
                    to={`/apply-job-${state.slug}`}
                    className="btn-apply-now d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Apply Now</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailsContent;
