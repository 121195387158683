const AboutItem=[
    {
        id:1,
        cat:'clients',
        title:'Our Clients',
        desc:'Find here, most frequently asked questions and answers...',
        image: 'assets/img/menu/ourClient.webp',
        url:'/our-clients'
    },
    {
        id:2,
        cat:'partners',
        title:'Technology Partners',
        desc:'Find here, most frequently asked questions and answers...',
        image: 'assets/img/menu/knowledgebased.webp',
        url:'/our-technology-partners'
    },
    {
        id:3,
        cat:'aboutUs',
        title:'About Us',
        desc:'Your one Stop Shop for all Digital, Web, Data Science and Software solutions...',
        image: 'assets/img/menu/web_solution_firm-aboutus.webp',
        url:'/about-us-pages'
    },
    {
        id:4,
        cat:'career',
        title:'Career',
        desc:'Let’s solve some challenges together through technology...',
        image: 'assets/img/menu/web_solution_firm-career.webp',
        url:'/career-page'
    },
    {
        id:5,
        cat:'testimonial',
        title:'Testimonial',
        desc:'Let’s explore what our clients say about our solutions and service...',
        image: 'assets/img/menu/web_solution_firm-testimonial.webp',
        url:'/testimonials'
    },
    {
        id:6,
        cat:'faq',
        title:'FAQ',
        desc:'Find here, most frequently asked questions and answers...',
        image: 'assets/img/menu/web_solution_firm-knowledged-base.webp',
        url:'/faq'
    },
    {
        id:7,
        cat:'ourTeam',
        title:'Our Team',
        desc:'We hire people who are passionate about solving  problems through technology...',
        image: 'assets/img/menu/web_solution_firm-our-team.webp',
        url:'/our-teams'
        // url:'/our-team-details'
    },
];

export default AboutItem;