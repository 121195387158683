import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


const AboutUsPage = () => {

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white text-center">
                        <span className='title'>About Us</span>
                        <span className='text-center subTitle'>
                            {/* It is a long established fact that a reader will be. */}
                            Moving your business from garage to global
                        </span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Portfolio Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#mission' scroll={el => scrollWithOffset(el)} >Our Mission</Link>
                                        <Link smooth to='#vission' scroll={el => scrollWithOffset(el)} >Our Vission</Link>
                                        <Link smooth to='#value' scroll={el => scrollWithOffset(el)} >Our Values</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ======= Contact Section =======  */}

                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                        <div id='mission' className="row gx-0">
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Our Mission</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our mission is to enhance the business growth of our clients by a data- driven approach. We are very friendly professionals which helps us retain existing clients and expand the customer circle.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img//company/aboutUs/web_solution_firm_our-mission-01.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>

                        <div id='vission' className="row gx-0">                        
                            {/* Our Vission Start */}
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img//company/aboutUs/web_solution_firm_our-vission.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Our Vission</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Aggregating most popular technologies “Web, Data Science, Computer Vision, Machine learning, IoT, Cloud Computing, in one platform gives users the best experience and boosts the business.
                                    </p>
                                </div>
                            </div>
                            {/* Our Vission Ends */}
                        </div>

                        <div id='value' className="row gx-0">
                            {/* Our Values Start */}                            
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Our Values</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Innovation
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Customer Success
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Problem Solving
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Data Driven culture
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img//company/aboutUs/web_solution_firm_picture.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Values End */}
                        </div>
                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default AboutUsPage;