import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import '../../home/home.css';
import PortfolioItem from '../../home/landingPageContent/PrortfolioItem';

const PortfolioData = () => {

    const[items, setItems] = useState(
        PortfolioItem.filter((currentElement) => {
            return currentElement.cat === 'webapp';
        })
    );

    const filterItem = (categItem) => {
        
        const updateItems = PortfolioItem.filter((currentElement) => {
            return currentElement.cat === categItem;
        });

        setItems(updateItems);
        console.log(items);
    }

    return (
        <>
        
            <div style={{ backgroundColor:'#090046' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex ">
                            <ul id="stickyMenu-flterss">
                                <Link to='' className="focus" onClick={() => filterItem('webapp')}>Web Application</Link>
                                <Link to='' onClick={() => filterItem('ecommercesolution')}>eCommerce Solution</Link>
                                <Link to='' onClick={() => filterItem('creative')}>Creative</Link>
                                <Link to='' onClick={() => filterItem('dataanalytics')}>Data Analytics</Link>
                                <Link to='' onClick={() => filterItem('digitalmarketing')}>Digital Marketing</Link>
                                <Link to='' onClick={() => filterItem('mobileapp')}>Mobile Application</Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                <header className="section-header">
                    {/* <p>Protfolio</p> */}
                </header>
                <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                    {
                        items.map((elem) => {
                            const {id, laptop, image} = elem;

                            return elem.cat === "mobileapp" ?

                                // Mobile App Portfolio Design
                                <div className="col-lg-3 col-md-3 portfolio-item filter-web" key={id}>
                                    <div className='bgImg'>
                                    <img alt="mobile-iframe" className="mobile-iframe" src={laptop} />
                                        <div className='mobile-box'>
                                            <img src={image} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            :

                            // All Web View Portfolio Design
                            <div className="col-lg-6 col-md-6 portfolio-item filter-web" key={id}>
                                <div className='bgImg'>
                                <img alt="laptop-iframe" className="iframe" src={laptop} />
                                    <div className='box'>
                                        <img src={image} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>

                        })
                    }

                </div>
            </div>
        </>
    );
};

export default PortfolioData;