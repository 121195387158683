import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


const CorporateSolution = () => {

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Corporate Solution</span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#ams' scroll={el => scrollWithOffset(el)} >Asset Booking System</Link>
                                        <Link smooth to='#fms' scroll={el => scrollWithOffset(el)} >Fleet Management with GPS Tracking</Link>
                                        <Link smooth to='#rbms' scroll={el => scrollWithOffset(el)} >Meeting Room Booking System</Link>
                                        <Link smooth to='#erps' scroll={el => scrollWithOffset(el)} >ERP Solution</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='ams' className="row gx-0">
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Asset Booking System</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Graphics Design Start */}
                        <div id='fms' className="row gx-0">  
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Fleet Management with GPS Tracking</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Graphics Design End */}

                        {/* Logo Design Start */}
                        <div id='rbms' className="row gx-0">                         
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Meeting Room Booking System</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Logo Design End */}

                        {/* Brand Identity Start */}
                        <div id='erps' className="row gx-0">
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/solution/corporate/web_solution_firm_erp-solution.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>ERP Solution</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Brand Identity End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default CorporateSolution;