import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { AiFillCalendar } from "react-icons/ai";
import { AiFillExperiment } from "react-icons/ai";
import { VscTerminalLinux } from "react-icons/vsc";
import { FaEye } from "react-icons/fa";

function JobPostList() {
  const [jobPost, setJobPost] = useState([]);
  useEffect(() => {
    axios.get(`https://nz.websolutionfirm.com/api/get-job-post-list`).then((res) => {
      if (res.status === 200) {
        setJobPost(res.data.career);
        console.log(res.data.career)
      }
    });
  }, []);

  return (
    <>
      {jobPost.map((item, index) => (
        <div className="col-lg-6 col-md-6" key={index}>
          <div className="cardBox" style={{ marginTop: "10px" }}>
            <ul className="row">
              <div className="col-md-12">
                <li className="name jobTitle" style={{ paddingBottom: "5px" }}>
                  <b>
                    {" "}
                    <Link to={`/${item.slug}`}>
                      {item.titleOrDesignationn}
                    </Link>{" "}
                  </b>
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <BsFillJournalBookmarkFill />
                  </span>
                  {item.jobID}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <ImLocation2 />
                  </span>
                  {item.jobLocation}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <VscTerminalLinux />
                  </span>
                  {item.employmentStatus}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <AiFillExperiment />
                  </span>
                  {item.yearOfExperience}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <AiFillCalendar />
                  </span>
                  {item.applicationDeadline}
                  <Link
                    to={`/${item.slug}`}
                    style={{ float: "right", fontSize: "25px" }}
                  >
                    <FaEye />
                  </Link>
                </li>
              </div>
              <div className="col-md-2">
                {/* <img src="assets/img/team/team-1.jpg" className="img-fluid" height={'230px'} alt="" /> */}
              </div>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
}

export default JobPostList;
