import React from "react";
// import { Link } from 'react-router-dom';
import { HashLink as Link } from "react-router-hash-link";
import "../../home/home.css";

import { AiOutlineSlack } from "react-icons/ai";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -140;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const EcommerceSolution = () => {
  return (
    <>
      {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
      <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0.9s"
        >
          <p className="text-white">
            <span className="title text-center">
              Self-Hosted eCommerce Solutions
            </span>
          </p>
        </div>
      </section>

      <main className="main">
        {/* ======= Sticky Menu Section ======= */}
        <section
          id="portfolio"
          className="portfolio stickyPageMenu"
          style={{ marginTop: "-50px" }}
        >
          <div style={{ backgroundColor: "#090046" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-10 d-flex ">
                  <ul id="stickyMenu-flterss">
                    <Link
                      smooth
                      to="#singleVendor"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      Single Vendor eCommerce
                    </Link>
                    <Link
                      smooth
                      to="#multiVendor"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      Multi Vendor eCommerce
                    </Link>
                    <Link
                      smooth
                      to="#budgetEcommerce"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      Budget eCommerce
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ======= Content Section =======  */}
        <section className="about">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="0.9s"
          >
            {/* Web Design Start */}
            <div id="singleVendor" className="row gx-0">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="0.9s"
              >
                <div className="content">
                  <h2>Single Vendor eCommerce</h2>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    Whether you’re just starting out an online business or an
                    established high-volume business, our ecommerce solutions
                    have the features to boost your online growth. Client
                    focused, creating strategic digital shopping solutions that
                    deliver tangible business results.
                  </p>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    This solution comes with customer login & profile, ecommerce
                    front-end, admin login, Dashboard, order management,
                    inventory management, supplier management, Advanced
                    analytics & reporting, Accounts module footprint, system
                    configuration portal, payment gateway integration and many
                    more.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <img
                  src="assets/img/solution/eCommerce/web_solution_firm_ecommerce-single-vendor.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
              {/* Our Mission End */}
            </div>
            {/* Web Design End */}

            {/* Google Analytics Start */}
            <div id="multiVendor" className="row gx-0">
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay="200"
                style={{ paddingTop: "20px" }}
              >
                <img
                  src="assets/img/solution/eCommerce/web_solution_firm_ecommerce-multi-vendor.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="0.9s"
              >
                <div className="content">
                  <h2>Multi-Vendor eCommerce</h2>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    Multi-Vendor eCommerce Also, known as Multi-Vendor
                    Marketplace. Rather than launching your products on
                    platforms like Amazon, eBay and Etsy, the opportunity has
                    never been greater to follow their lead and build your own
                    eCommerce marketplace instead. A Multi-vendor marketplace is
                    a large-scale eCommerce store where multiple vendors can
                    sell their products and services.
                  </p>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    This solution comes with customer login & profile,
                    vendor/seller login, profile, dashboard, admin panel.
                    ecommerce front-end, marketplace admin login, employee
                    login, profile, Dashboard, order management, inventory
                    management, supplier management, Advanced analytics &
                    reporting, Accounts module footprint, system configuration
                    portal, payment gateway integration and many more
                  </p>
                </div>
              </div>
            </div>
            {/* Google Analytics End */}

            {/* Budget Ecommerce Start */}
            <div id="budgetEcommerce" className="row gx-0">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="0.9s"
              >
                <div className="content">
                  <h2>Budget eCommerce</h2>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    If you want to start a small business or have a small budget
                    and want to put your business online and let customers shop
                    from your ecommerce, then this is a good plan to think
                    about. We use Website builders or CMS with eCommerce
                    plugins, such as WordPress + WooCommerce. They allow
                    creating multi-purpose eCommerce websites with blogging and
                    shopping functionality.
                  </p>
                  <p>
                    <span className="iconSize">
                      <AiOutlineSlack />
                    </span>
                    We also develop Paid hosted eCommerce website platforms,
                    such as Shopify or BigCommerce.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay="200"
                style={{ paddingTop: "20px" }}
              >
                <img
                  src="assets/img/solution/eCommerce/web_solution_firm_budget_e-commerce.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* Budget Ecommerce End */}
          </div>
        </section>

        {/* ======= End Contact Section ======= */}
      </main>
      {/* <section className=""></section> */}
    </>
  );
};

export default EcommerceSolution;
