import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../home/home.css";

const ClientData = () => {

    // Client Logo List
    const [client, setClient] = useState([]);
    useEffect(() => {
        axios
        .get(`https://nz.websolutionfirm.com/api/our-clients-logo-list`)
        .then((res) => {
            if (res.status === 200) {
            setClient(res.data.clientLogo);
            }
        });
    }, []);
    
    return (
        <>
            {
                client.map((item, idx) => (

                    <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s" key={idx}>
                        <div className="valuedclients center our-valued-clients box1">
                            <a href={item.hyperlink} target="_self" rel="noreferrer">
                                <img className="img-fluids" alt="" 
                                    src={
                                        "https://nz.websolutionfirm.com/public/upload/client/" +
                                        item.company_logo
                                    }
                                />
                            </a>
                        </div>
                    </div>
                ))
            }
        </>
    );
};

export default ClientData;
