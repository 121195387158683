import React from 'react';
import '../home.css';

import {Link} from 'react-router-dom';


import { GiShoppingCart } from "react-icons/gi";
import { BsPencilSquare } from "react-icons/bs";
import { MdOutlineConstruction } from "react-icons/md"; 
import { MdBusiness } from "react-icons/md"; 
import { GrAnalytics } from "react-icons/gr";
import { HiOutlineSpeakerphone } from "react-icons/hi";

const Solution = () => {
    return (
        <>
            <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                <header className="section-header">
                    <p className='blue-un'>We have developed Solutions for</p>
                </header>
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        {/* <div className="solution-box blue rotates"> */}
                        <div className="solution-box blue">
                            <i className="icon">
                                <GiShoppingCart />
                            </i>
                            <h3>eCommerce Solution</h3>
                            <p>Opportunity has never been greater to follow your patience and build your own eCommerce marketplace to boost your online growth.</p>
                            <Link to="ecommerce-solution" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        <div className="solution-box orange">
                            <i className="icon">
                                <BsPencilSquare />
                            </i>
                            <h3>EdTech Solution</h3>
                            <p>A Complete scalable cloud-based Learning & Development Platform. All in-one system that brings together learning, growth, & management.</p>
                            <Link to="edtech" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        <div className="solution-box green">
                            <i className="icon">
                                <MdOutlineConstruction />
                            </i>
                            <h3>Small to Medium Business </h3>
                            <p>Our customizable software is able to create digital solutions that deliver tangible business results with Data driven decission.</p>
                            <Link to="small-to-medium-business" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        <div className="solution-box red">
                            <i className="icon">
                                <MdBusiness />
                            </i>
                            <h3>Corporate Solution</h3>
                            <p>We offer different types of software solutions for big corporations, for example ERP, BIG Data, Customized software solution.</p>
                            <Link to="corporate" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        <div className="solution-box purple">
                            <i className="icon">
                                <GrAnalytics />
                            </i>
                            <h3>Consultancy </h3>
                            <p>We offer different types of consultancy for example Data , IT, Software, security and digital marketing consultancy.</p>
                            <Link to="consultency" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 bounceClass" >
                        <div className="solution-box pink">
                            <i className="icon">
                                <HiOutlineSpeakerphone />
                            </i>
                            <h3>Digital Marketing & SEO</h3>
                            <p>Our Digital marketing campaigns involve integrating multiple digital channel strategies for sales and helping you grow.</p>
                            <Link to="others" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Solution;