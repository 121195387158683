import React from 'react';
import '../home.css';

const OurClients = () => {
    return (
        <>
            <div >
                <div className="col-lg-12 text-center">
                    <header className="section-header">
                        <p className="blue-un">Our Valuable Clients</p>
                    </header>
                </div>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                
                    <div className="row gy-4">
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/pa1l.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/masco.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/successbuilding.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/pea.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/raishaHome.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/xgodgame.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/fairTicket.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/proReview.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/sms.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/colorDotBD.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/bestHome.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div className="valuedclients center our-valued-clients box1">
                                <a href="#f" target="_blank" rel="noreferrer">
                                    <img className="img-fluids" src="assets/img/clients/localHouse.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurClients;