import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ServicesItem from './ServicesItem';

import '../home.css';
import '../headerMenu.css';

const Services = () => {

    const[items] = useState(ServicesItem);

    return (
        <>         
            <div className="col-lg-12 servicesMenu">
                <div className="row services-cards">

                    {
                        items.map((elem) => {
                            const {id, url, title, desc, image} = elem;
                            return (
                                <div className="col-lg-2 services-cards-body" key={id}>
                                    <div className="img">
                                        <Link to={url} ><img src={image} alt="" /></Link>
                                    </div>
                                    <h5>{title}</h5>
                                    <p>{desc}</p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    );
};

export default Services;