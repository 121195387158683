import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


const EdTechSolution = () => {

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>EdTech Solution</span> 
                        <span className='text-center subTitle'>A Complete scalable cloud-based Learning & Development Platform</span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#webDesign' scroll={el => scrollWithOffset(el)} >School Management</Link>
                                        <Link smooth to='#graphicsDesign' scroll={el => scrollWithOffset(el)} >Online Learning Management</Link>
                                        <Link smooth to='#logoDesign' scroll={el => scrollWithOffset(el)} >EAP System</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='webDesign' className="row gx-0">
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>School management System</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        All in-one system that brings together learning, growth, skills, management, and capabilities into a personalized experience.
                                    
                                        Whether you are training internal students, external audiences, or worldwide student/partners/staff, our inhouse developed School management System empowers you to thrive by tapping learning technology, proven to scale as your business grows. Users can create unique branding for different departments, or clients. Additionally, its responsive learner interface works on any device.
                                    </p>
                                    <p> 
                                        - Best UX & UI, Easy to use <br />
                                        - Quick import of new learners <br />
                                        - Easily upload any type of multimedia <br />
                                        - Customizable interface for branding <br />
                                        - Out of the box solution at a reasonable price point <br />
                                        - Good Course Management. <br />
                                        - Easy to sync with attendance management <br />
                                        - Accounts, Exam, & result management <br />
                                        - Online class & course management <br />
                                        - Chat box & Group discussion form <br />
                                        - Admin, employee, Teacher, & student login and dedicated dashboard.  <br />
                                    </p>
                                    <p> 
                                        
                                        
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/solution/edTech/web_solutionfirm_firm-school-management system.jpg" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Graphics Design Start */}
                        <div id='graphicsDesign' className="row gx-0">  
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/solution/edTech/web_solution_firm_lms.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Learning Management System</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Unlock the power of learning with LMS. Combining user-focused technology and industry-leading expertise. Our LMS is a powerful cloud-based learning management system (LMS) designed to boost engagement and learning outcomes, while still being incredibly scalable and usable. 
                                    </p>
                                    <p> 
                                        - Smart Administration Manage your learning and development program in a fraction of the time. <br />
                                        - Learner Engagement Engage learners with easily digestible learning content on any device. <br />
                                        - eCommerce Sell and manage access to online courses, webinars and more. <br />
                                        - Multiple user profile:-  Admin, employee, Teacher, & student login and dedicated dashboard.  <br />
                                        - Reporting & Analytics Make data-driven decisions with configurable reports you can schedule ahead of time  <br />
                                        - Learner Experience Personalize learning with an LMS that delivers unique training experiences to different users.<br />
                                        - Performance Scale your learning program to meet to meet evolving learner and organizational needs.<br />
                                        - Content Libraries Add pre-built courses from leading subject matter experts across dozens of industries and professions.<br />
                                        - E-Signatures Meet regulatory compliance requirements without the burden of manual record keeping.<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Graphics Design End */}

                        {/* Logo Design Start */}
                        <div id='logoDesign' className="row gx-0">                         
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>EAP System</h2>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/solution/edTech/web_solution_firm-eap.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Logo Design End */}
                        
                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default EdTechSolution;