import React from "react";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import {useNavigate} from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";

import "../../../home/home.css";
import './careerPage.css';
import "../../../App.css";

const ApplyForm = () => {

    const { jobId } = useParams();

    const [state, setState] = useState({});

    useEffect(() => {
        myFunction();
        return () => {
            setState({}); // This worked for me
        };
    },[]);
    
    const myFunction = () => {
        axios.get(`https://nz.websolutionfirm.com/api/job-types/${jobId}`).then((res) => {
        if (res.status === 200) {
            setState(res.data);
        }
        console.log(res.data.job_type);
        });
    }

    
    const [job_id, setJobId] = useState(jobId);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [nid, setNid] = useState("");
    const [qualification, setQualification] = useState("");
    const [experience, setExperience] = useState("");

    const [current_salary, setCurrentSalary] = useState("");
    const [expected_salary, setExpectedSalary] = useState("");

    const [portfolio, setPortfolio] = useState("");
    const [github, setGithub] = useState("");
    const [figma, setFigma] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [note, setNote] = useState("");
    const [additional_notes, setAdditional_notes] = useState("");

    const [cv, setCv] = useState([]);
    const [image, setPictures] = useState([]);

    const [isPending, setIsPending] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append("job_id", job_id);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("nid", nid);
        formData.append("qualification", qualification);
        formData.append("experience", experience);

        formData.append("current_salary", current_salary);
        formData.append("expected_salary", expected_salary);

        formData.append("portfolio", portfolio);
        formData.append("github", github);
        formData.append("figma", figma);
        formData.append("linkedin", linkedin);
        formData.append("note", note);
        formData.append("additional_notes", additional_notes);

        formData.append("cv", cv);
        formData.append("image", image);

        setIsPending(true);
        
        fetch("https://nz.websolutionfirm.com/api/save-job-application", {
            method: 'POST',
            // headers: {"Content-Type": "application/json"},
            body: formData
        }).then((res) => {

            console.log(res);

            switch (res.status) {
                case 401:
                  swal({
                    title: "Failed!",
                    text: "Please Check Required Fields.",
                    icon: "error",
                    button: "OK!",    
                  });

                  break;
                case 201:
                  swal({
                    title: "Success!",
                    text: "Successfully Your Massage has been Send to WSF.",
                    icon: "success",
                    button: "OK!",
                  });

                  break;
                default:
                  break;
            }

        setIsPending(false)

        setJobId('');
        setName('');
        setEmail('');
        setPhone('');
        setNid('');
        setQualification('');
        setExperience('');

        setCurrentSalary('');
        setExpectedSalary('');

        setPortfolio('');
        setGithub('');
        setFigma('');
        setLinkedin('');
        setNote('');
        setAdditional_notes('');
        setCv('');
        setPictures('');
        navigate('');
    })
}

  return (
    <div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="banner">
                <h1>Job Application Form</h1>
                
            </div>
            <div className="item">
                {/* <p>Job ID </p> */}
                <input type="hidden" name="job_id" readOnly
                    value={12}
                />
            </div>

            <div className="item">
                <p>
                    Full Name <span className="danger">*</span>
                </p>
                <input type="text" name="name" required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="item">
                <p>Email <span className="danger">*</span></p>
                <input type="email" name="email" required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="item">
                <p>Phone <span className="danger">*</span></p>
                <input type="text" name="phone" required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className="item">
                <p>NID </p>
                <input type="number" name="nid" 
                    value={nid}
                    onChange={(e) => setNid(e.target.value)}
                />
            </div>
            <div className="item">
                <p>Last Qualification <span className="danger">*</span></p>
                <input type="text" name="qualification" required
                    value={qualification}
                    onChange={(e) => setQualification(e.target.value)}
                />
            </div>
            <div className="item">
                <p>Years of Experience <span className="danger">*</span></p>
                <input type="number" name="experience" required
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                />
            </div>
            
            {state.job_type == 1 && 
            <div className="item">
                <p>Salary <span className="danger">*</span></p>
                <div className="city-item">
                    <input type="number" name="current_salary" required placeholder="Current Salary" 
                        value={current_salary}
                        onChange={(e) => setCurrentSalary(e.target.value)}
                    />
                    <input type="number" name="expected_salary" required placeholder="Expected Salary" 
                        value={expected_salary}
                        onChange={(e) => setExpectedSalary(e.target.value)}
                    />
                </div>
            </div>
            }

            <div className="item">
                <p>Social Media</p>
                <div className="city-item">
                    <input type="text" name="portfolio" placeholder="Portfolio" 
                        value={portfolio}
                        onChange={(e) => setPortfolio(e.target.value)}
                    />
                    <input type="text" name="github" placeholder="Github" 
                        value={github}
                        onChange={(e) => setGithub(e.target.value)}
                    />
                    <input type="text" name="figma" placeholder="Figma/Canva" 
                        value={figma}
                        onChange={(e) => setFigma(e.target.value)}
                    />
                    <input type="text" name="linkedin" placeholder="Linkedin" 
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                </div>
            </div>
            <div className="item">
                <p>Why you want to work for Web Solution Firm ?</p>
                <textarea name="note" rows="3" 
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                ></textarea>
            </div>
            <div className="item">
                <p>Additional Message (if any)</p>
                <textarea name="additional_notes" rows="3"  
                    value={additional_notes}
                    onChange={(e) => setAdditional_notes(e.target.value)}
                ></textarea>
            </div>
            <div className="item">
                <p>Upload Your Resume ( File type must be pdf, max 2MB ) <span className="danger">*</span> </p>
                <input type="file" name="cv" required 
                    onChange={(e) => setCv(e.target.files[0])}
                />
            </div>

            <div className="item">
                <p>Upload Your Photo ID ( Image type must be png, jeg, jpeg, max 500kb ) </p>
                <input type="file" name="image"
                    onChange={(e) => setPictures(e.target.files[0])}
                />
            </div>
            <div className="btn-block">
                {!isPending && <button type="submit" >Send Application</button>}
                {isPending && <button disabled className="btn btn-info">Send Application to WSF ... </button>}
            </div>
        </form>
    </div>
  );
};

export default ApplyForm;
