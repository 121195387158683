import React from 'react';
import '../home/home.css';

// Import landingPageContent Component from home/landingPageContent
import OurServices from './landingPageContent/OurServices';
import Achivement from './landingPageContent/Achivement';
import OurProduct from './landingPageContent/OurProduct';
import OurClients from './landingPageContent/OurClients';
import TechnologyPartner from './landingPageContent/TechnologyPartner';
// import Portfolio from './landingPageContent/PortfolioTest';
import LandingPortfolio from './landingPageContent/LandingPortfolio';
import Newsletter from './landingPageContent/Newsletter';
import Solution from './landingPageContent/Solution';
import ContactUs from './landingPageContent/contactUs/ContactUs';
import Testimonial from '../home/landingPageContent/Testimonial';

// import bgHome from '../assets/images/bgHome.png';
import bgHome from '../assets/images/bgHome.png';
import bgHomeWorkProcess from '../assets/images/OurProcessBackground.jpg';

const LandingPage = () => {
    return (
        <> 
            {/* Our Services Start */}
            <section loading="lazy" id="services" className="services">
                <OurServices />
            </section>
            {/* Our Services End */}

            {/* Our Achivement Start */}
            <section loading="lazy" className="counts" style={{backgroundColor: "var(--color-light-blue)", backgroundImage: `url(${bgHome})`, backgroundSize: "cover"}}>
                <Achivement />
            </section>
            {/* Our Achivement End */}

            {/* ======= Our Product =======  */}
            <section loading="lazy" className="about">
                <OurProduct />
            </section>
            {/* ======= End Our Product Section =======  */}

            {/* ======= Our Valuable Clients ======= */}
            {/* <section id="clients" className="clients" style={{backgroundColor: "var(--color-light-blue)", backgroundImage: `url(${bgHome})`, backgroundSize: "cover"}}> */}
            <section loading="lazy" id="clients" className="clients">
                <OurClients />
            </section>
            {/* ======= End Our Valuable Clients =======  */}

            {/* ======= Portfolio Section ======= */}
            <section loading="lazy" id="portfolio" className="portfolio">
                <LandingPortfolio />
            </section> 
            {/* ======= End of Portfolio Section ======= */}

            {/* ======= Our Work Process Section =======  */}
            <section loading="lazy" id="contact" className="contact overlay" 
                style={{
                    background: `url(${bgHomeWorkProcess}), linear-gradient(#480048cc, #c04848cc)`, 
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat'
                }}>
                <Newsletter />
            </section>
            {/* ======= End Our Work Process Section ======= */}

            {/* ======= Our Technology Partners Section ======= */}
            <section loading="lazy" id="clients" className="clients">
                <TechnologyPartner />
            </section>
            {/* ======= End Our Technology Partners Section ======= */}

            {/* ======= We Offer Solutions Section ======= */}
            <section loading="lazy" id="solution" className="solution">
                <Solution />
            </section>
            {/* ======= End We Offer Solutions Section ======= */}

            {/* ======= Contact Section =======  */}
            <section loading="lazy" id="contact" className="contact" style={{ backgroundImage: `url("assets/img/contact.WebP")` }}>
                <ContactUs />
            </section>
            {/* ======= End Contact Section ======= */}

            {/* ======= Testimonials Section ======= */}
            <section loading="lazy" id="testimonials" className="testimonials">
                <Testimonial />
            </section>
            {/* ======= End Testimonials Section =======  */}


        
        </>
    );
};

export default LandingPage;