import React from "react";
import { useState } from "react";
import "../../home.css";
import swal from "sweetalert";
import {useNavigate} from "react-router";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [note, setNote] = useState("");

    const [isPending, setIsPending] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        const contactUs = {name, phone, email, subject, note};
        setIsPending(true);
        fetch("https://nz.websolutionfirm.com/api/save-contact", {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(contactUs)
        }).then((res) => {
            switch (res.status) {
                case 422:
                  swal({
                    title: "Failed!",
                    text: "All field are Required & Email Must be Unique",
                    icon: "error",
                    button: "OK!",
                  });
        
                  break;
                case 201:
                  swal({
                    title: "Success!",
                    text: "Successfully Your Massage has been Send to WSF.",
                    icon: "success",
                    button: "OK!",
                  });
        
                  break;
                default:
                  break;
              }

            setIsPending(false)
            setName('');
            setPhone('');
            setEmail('');
            setSubject('');
            setNote('');
            navigate('');
        })
      }
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="php-email-form contactUs"
        style={{ backgroundColor: "var(--color-light-blue-opacity)" }}
      >
        <header className="section-header">
          <p className="text-white">Tell us more about your project</p>
        </header>
        <div className="row gy-4">
          <div className="col-md-12">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control text-white"
              placeholder="Your Full Name "
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
          </div>
          <div className="col-md-12 ">
            <input
              type="number"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control text-white"
              placeholder="Your Phone Number"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
          </div>
          <div className="col-md-12 ">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control text-white"
              placeholder="Your Email Address"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="form-control text-white"
              placeholder="Subject"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
          </div>
          <div className="col-md-12">
            <textarea
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="form-control text-white"
              rows="6"
              placeholder="Write your Text"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            ></textarea>
          </div>
          <div className="col-md-12">
            
            {!isPending && <button type="submit" className="btn btn-info">Submit</button>}
            {isPending && <button disabled className="btn btn-info">Sending ... </button>}
              
          </div>
        </div>
      </form>
    </>
  );
}
export default ContactForm;
