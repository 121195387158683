import React, {useState} from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import SolutionItem from './SolutionItem';

import '../home.css';
import '../headerMenu.css';
import { Link, NavLink } from 'react-router-dom';


const Solution = () => {

    const[items, setItems] = useState(
        SolutionItem.filter((currentElement) => {
            return currentElement.cat === '';
        })
    );

    const filterItem = (categItem) => {
        const updateItems = SolutionItem.filter((currentElement) => {
            return currentElement.cat === categItem;
        });
        setItems(updateItems);
    }

    return (
        <>
            <div className="col-lg-3 solutionMenu">
                <NavLink to='ecommerce' style={{ marginBottom:'5px' }} onClick={() => filterItem('ecommerce')} >eCommerce Solution <IoIosArrowForward /></NavLink>
                <NavLink to='software' style={{ marginBottom:'5px' }} onClick={() => filterItem('edtech')}>EdTech Solution <IoIosArrowForward /></NavLink>
                <NavLink to='smallbusiness' style={{ marginBottom:'5px' }} onClick={() => filterItem('smallbusiness')}>Small to Medium Business <IoIosArrowForward /></NavLink>
                <NavLink to='corporates' style={{ marginBottom:'5px' }} onClick={() => filterItem('corporate')}>Corporate Solution <IoIosArrowForward /></NavLink>
                <NavLink to='consultencys' style={{ marginBottom:'5px' }} onClick={() => filterItem('consultency')}>Consultancy <IoIosArrowForward /></NavLink>
                {/* <NavLink to='as' style={{ marginBottom:'5px' }} onClick={() => filterItem('smallbusiness')}>Small Business <IoIosArrowForward /></NavLink> */}
            </div>
            
            <div className="col-lg-9">
                <div className="row solution-cards">

                    {
                        items.map((elem) => {
                            const {id,title, desc, image,url} = elem;
                            return (
                                <div className="col-lg-3 solution-cards-body" key={id}>
                                    <div className="img">
                                        <Link to = {url} ><img src={image} alt="" /></Link>
                                    </div>
                                    <h5>{title}</h5>
                                    <p>{desc}</p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    );
};

export default Solution;