import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai"; 
import { MdCloudUpload } from "react-icons/md"; 

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const DomainHosting = () => {

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Domain & Hosting Services</span> 
                        <span className='text-center subTitle'>
                            We offer Maximum Server Security and High Speed 
                        </span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#domainTransfer' scroll={el => scrollWithOffset(el)} >Domain Name</Link>
                                        <Link smooth to='#cloudHosting' scroll={el => scrollWithOffset(el)} >Cloud Hosting</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Transfer Start */}
                        <div id='domainTransfer' className="row gx-0">
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Domain Name</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        You absolutely deserve a good domain name for your business as it gives your business instant credibility and puts you in the same online marketplace as your major competitors. Any domain name can take your business online, however having the wrong domain name can cost you $$$$ extra in future when you will do SEO, digital marketing and so on.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We all get excited when we are starting a new business or taking an existing business online. We buy a domain name that looks good to us. However, this domain name may not be the right for your business sector.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our dedicated and industry expert team will be more than happy to help you doing market research and find the right domain name for your business.
                                    </p>
                                    <p> 
                                        # We can get you a new domain name.<br /> # We do domain transfer from A to B. <br /># We can get your bulk domain name.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/services/domain/web_solution_firm_domain.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Cloud Hosting Start */}
                        <div id='cloudHosting' className="row gx-0">  
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/domain/web_solution_firm_hosting.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Cloude Hosting</h2>
                                    <p> 
                                        <span className='iconSize'><MdCloudUpload /></span>
                                        Who does not want the best and most secure server to host the solution on cloud. We have servers and Data centres across the world. We offer a wide range of hosting solutions to entertain your business requirements. We usually offer customised hosting solutions based on your business needs.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><MdCloudUpload /></span>
                                        Our Cloud Hosting package includes
                                    </p>
                                    <p style={{ marginTop:'-20px' }}> 
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                #  NVMe SSD Storage <br />
                                                #  Litespeed Web Server <br />
                                                #  Addon Domain<br />
                                                #  Sub Domains<br />
                                                #  Free site Builder<br />
                                                #  SQL and No SQL Databases<br />
                                                #  99.9% UPTIME GUARANTEE<br />
                                            </div>
                                            <div className='col-lg-6'>
                                                #  SSL Certificate <br />
                                                #  Email Accounts <br />
                                                #  cPanel/Control Panel<br />
                                                #  One Click app install<br />
                                                #  24/7 support<br />
                                                #  3000 GB / 3 TB Data Transfer yearly<br />
                                                #  Maximum Server Security<br />
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Cloud Hosting End */}

                        {/* Cloud Hosting Start */}
                        
                        {/* Cloud Hosting End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default DomainHosting;