const ServicesItem=[
    {
        id:1,
        cat:'webApplication',
        title:'Web Application',
        desc:'Don’t leave an idea in the pipeline for long, let’s convert the idea to a web application.',
        image: 'assets/img/menu/web_solution_firm-web application.webp',
        url: 'web-application-details'
    },
    {
        id:2,
        cat:'softwareDevelopment',
        title:'Software Development',
        desc:'We have been developing a small software solution to ERP. Nothing is too small or too big.',
        image: 'assets/img/menu/web_solution_firm-software-development.webp',
        url: 'software-development-details'
    },
    {
        id:3,
        cat:'mobileApplication',
        title:'Mobile Application',
        desc:'You have an idea to make a mobile app & not sure How to develop it. Don’t worry We...',
        image: 'assets/img/menu/web_solution_firm-mobileapp.webp',
        url: 'mobile-application-details'
    },
    {
        id:4,
        cat:'creative',
        title:'Creative',
        desc:'We have a dedicated creative team to produce your website, logo, brochure, UX & UI...',
        image: 'assets/img/menu/web_solution_firm-creative.webp',
        url: 'creative-details'
    },
    {
        id:5,
        cat:'digitalMarketing',
        title:'Digital marketing',
        desc:'We do creative, visual, & data-driven digital marketing to drive your sales & profit...',
        image: 'assets/img/menu/web_solution_firm-digital-marketing.webp',
        url: 'digital-marketing-and-seo-details'
    },
    {
        id:6,
        cat:'domainHosting',
        title:'Domain & Hosting',
        desc:'You need the best and most secure server to host your solution on cloud across...',
        image: 'assets/img/menu/web_solution_firm-cloud-hosting.webp',
        url: 'domain-and-hosting-details'
    },
];

export default ServicesItem;