import React from 'react';
import '../home.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper.min.css'

const Video = () => {
    return (
        <>
            <div className=''>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                    <header className="section-header">
                        <p className='blue-un'>Testimonial</p>
                    </header>
                    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        <Swiper
                            breakpoints={{
                                1200: {
                                  // width: 576,
                                  slidesPerView: 3,
                                },
                                991: {
                                  // width: 991,
                                  slidesPerView: 1,
                                },
                                768: {
                                  // width: 768,
                                  slidesPerView: 1,
                                },
                            }}
                            modules={[Autoplay]}
                            spaceBetween={15}
                            // slidesPerView={3}
                            autoplay={true}
                        >
                            <SwiperSlide>
                                <div className="team ">
                                    <div className="testimonial-item">
                                        <div className="member">
                                            <div className="member-img">
                                                <img src="assets/img/team/lutfurHasan.jpg" className="img-fluid" alt="" />
                                                {/* <div className="social">
                                                    <a href="#a"><i className="bi bi-twitter"></i></a>
                                                    <a href="#a"><i className="bi bi-facebook"></i></a>
                                                    <a href="#a"><i className="bi bi-instagram"></i></a>
                                                    <a href="#a"><i className="bi bi-linkedin"></i></a>
                                                </div> */}
                                            </div>
                                            <div className="member-info">
                                                <h4>Lutfur Hasan</h4>
                                                <span>Founder and Director</span>
                                                <p>I have developed a CRM software by a Web Solution Firm. They are a highly technical and deadline oriented team. Team maintained good communication with me. Highly recommended.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End testimonial item  */}
                            <SwiperSlide>
                                <div className="team">
                                    <div className="testimonial-item" >
                                        <div className="member">
                                            <div className="member-img">
                                                <img src="assets/img/team/jessyHudson.jpg" className="img-fluid" alt="" />
                                            </div>
                                            <div className="member-info">
                                                <h4>Jessy Hudson</h4>
                                                <span>Team Manager</span>
                                                <p>5 Star service. Very friendly and professional team. They took my suggestions on board. It was a very difficult site and they did the job amicably with a good price. We highly recommend it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End testimonial item */}
                            <SwiperSlide>
                                <div className="team">
                                    <div className="testimonial-item">
                                        <div className="member">
                                            <div className="member-img">
                                                <img src="assets/img/team/robinThicke.jpg" className="img-fluid" alt="" />
                                            </div>
                                            <div className="member-info">
                                                <h4>Robin Thicke</h4>
                                                <span>Managing Director</span>
                                                <p>We are delighted with the service of Web Solution Firm. Our Company is based in Auckland, NZ. We have been using entire software and web service from Web solution Firm since last 5 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End testimonial item  */}
                            <SwiperSlide>
                                <div className="team">
                                    <div className="testimonial-item">
                                        <div className="member">
                                            <div className="member-img">
                                                <img src="assets/img/team/stephenieMealy.jpg" className="img-fluid" alt="" />
                                            </div>
                                            <div className="member-info">
                                                <h4>Stephenie Mealy</h4>
                                                <span>Chief Technology Officer</span>
                                                <p>Great job done by Web Solution Firm, excellent communication, friendly professional team. Masum Billah from Web Solution Firm was able to develop the entire system in three months. We highly recommend his service.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End testimonial item */}
                        </Swiper>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Video;