import React from 'react';
import {Link} from 'react-router-dom';
import '../home/home.css';

import footerLogo from '../assets/images/logo/footerLogo.png';

const FooterDetails = () => {

    return (
        <>
            <div className="container p-t30">
                <div className="row gy-4">
                    <div className="col-lg-2 col-6 footer-links footerTextColor">
                        <h4>Useful Links</h4>
                        <ul>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/">Home</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/about-us-pages">About us</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/services">Services</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/portfolio">Portfolio</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/contact-us">Contact Us</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/career-page">Career</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Solutions</h4>
                        <ul>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/ecommerce-solution"> eCommerce solution</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/edtech">EdTech Solution</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/small-to-medium-business">Trade Business Solution</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/corporate">ERP Solution</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/corporate">Booking system</Link></li>
                        <li><i className="bi bi-chevron-right"></i> <Link to="/small-to-medium-business">CRM</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-5 colo-12 footer-info text-center text-white">
                        <Link to="/" className="logo footerLogo d-flex align-items-center">
                            <img src={footerLogo} alt="logo" />
                        </Link>
                        
                        <div className="social-links mt-30">
                            <a href="#a" target= '_blank' className="twitter" style={{fontSize: "30px"}}>
                                <i className="bi bi-twitter"></i>
                            </a>
                            <a href="https://www.facebook.com/WebSolutionFirm" target= '_blank' rel="noopener noreferrer" className="facebook" style={{fontSize: "30px"}}>
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/websolutionfirm" target= '_blank' rel="noopener noreferrer" className="instagram" style={{fontSize: "30px"}}>
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/websolutionfirm" target= '_blank' rel="noopener noreferrer" className="linkedin" style={{fontSize: "30px"}}>
                                <i className="bi bi-linkedin"></i>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-white text-md-start">
                        <h4>Contact Us</h4>
                        <h6><strong>New Zealand (Head Quater):</strong></h6>
                        <p className="text-white"><i className="bi bi-telephone text-white" style={{ fontSize: "16px"}}></i> +642102362222</p>
                        <p className="text-white"><i className="bi bi-envelope text-white" style={{ fontSize: "16px" }}></i> masum@websolutionfirm.com</p>
                        <h6><strong>Bangladesh Office:</strong></h6>
                        <p className="text-white"><i className="bi bi-telephone text-white" style={{ fontSize: "16px" }}></i> +8809638695881</p>
                        <p className="text-white"><i className="bi bi-envelope text-white" style={{ fontSize: "16px" }}></i> info@websolutionfirm.com</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterDetails;