import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import HelpCenterItem from './HelpCenterItem';

import '../home.css';
import '../headerMenu.css';

const HelpCenter = () => {

    const[items] = useState(HelpCenterItem);

    return (
        <>         
            <div className="col-lg-12 helpCenterMenu">
                <div className="row aboutUs-cards">
                    {
                        items.map((elem) => {
                            const {id, title,desc,image,url} = elem;
                            return (
                                
                                <div className="col-lg-2 aboutUs-cards-body" key={id}>
                                    <div className="img">
                                        <Link to={url} ><img src={image} alt="" /></Link>
                                    </div>
                                    <h5>{title}</h5>
                                    <p>{desc}</p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    );
};

export default HelpCenter;